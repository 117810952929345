





































































































































import { Component, Vue } from "vue-property-decorator"
import { SET_PAGE_TITLE } from "@/store/store-types"

import HelloWorld from "@/components/HelloWorld.vue"
import ExpandableSection from "@/components/ExpandableSection.vue"
import FLButton from "@/components/base/FLButton.vue"
import NavDrawer from "@/components/base/NavDrawer.vue"
import Modal from "@/components/Modal.vue"
import { Notification, notify } from "@/util/notifications"
import DiceRoller from "@/components/dice/DiceRoller.vue"
import SvgIcon from "@/components/SvgIcon.vue"
import IconButton from "@/components/base/IconButton.vue"
import { Combat } from "@/components/CombatBox/CombatBox.vue"
import { Caesar } from "Tests/unit/data/personas"
import { FLToggle } from "@/components/base/FLToggle.vue"

@Component({
  components: {
    Combat,
    DiceRoller,
    ExpandableSection,
    FLButton,
    HelloWorld,
    IconButton,
    Modal,
    NavDrawer,
    SvgIcon,
    FLToggle,
  },
})
export class Sandbox extends Vue {
  modalOpen = false
  diceModalOpen = false
  navOpen = false
  toggleState = true

  caesar = Caesar

  mounted() {
    this.$store.commit(SET_PAGE_TITLE, "Sandbox")
  }

  notifyVue(
    message: string,
    type?: "info" | "warning" | "error",
    displayTime?: number
  ) {
    this.$notify({
      type: type || "info",
      message,
      displayTime,
    })
  }
  notify() {
    const msg: Notification = {
      type: "error",
      message: "raw notification",
      displayTime: 4000,
    }
    notify(msg)
  }
  click() {
    /* eslint-disable-next-line no-console */
    console.log("CLICK!")
  }

  get longWarningText() {
    return `Warning: Lorem ipsum dolor sit amet, consectetur adipiscing elit,
    sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
    enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
    aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit
    in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur
    sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt
    mollit anim id est laborum.`
  }
}

export default Sandbox
